<template>
  <div ref="animation" :class="['form',form?'animation_right_start':'animation_opacity']">
    <div class="form_item">
      <div class="form_item_label">公司名字</div>
      <div class="item_input">
        <input type="text" placeholder="请输入公司名字" v-model="franchise_form.companyName">
        <span>*</span>
      </div>
    </div>
    <div class="form_item">
      <div class="form_item_label">姓名</div>
      <div class="item_input">
        <input type="text" placeholder="请输入您的姓名" v-model="franchise_form.name">
        <span>*</span>
      </div>
    </div>
    <div class="form_item">
      <div class="form_item_label">手机号</div>
      <div class="item_input">
        <input type="text" placeholder="请输入您的手机号" v-model="franchise_form.phone">
        <span>*</span>
      </div>
    </div>
    <div class="form_item item_code">
      <div class="form_item_label">验证码</div>
      <div class="item_input">
        <input type="text" class="code" v-model="franchise_form.phoneCode">
<!--        <ImgVerify ref="verifyRef"></ImgVerify>-->
        <span class="change" @click="getPhoneCode">{{ codeName }}</span>
        <span>*</span>
      </div>
    </div>
    <div class="form_item">
      <div class="form_item_label">合作需求</div>
      <div class="item_input">
        <textarea placeholder="我们能为您做什么" v-model="franchise_form.remark"></textarea>
<!--        <span>*</span>-->
      </div>
    </div>
    <div class="check_message" v-text="check_message">
    </div>
    <div class="submit" @click="submit">
      提交
    </div>
    <!--  弹出验证 -->
    <el-dialog title="操作验证" :visible.sync="dialogTableVisible" :modal-append-to-body="false" top="10vh" style="width: 100%;margin: 0 auto">
      <el-form :model="codeForm" @submit.native.prevent>
        <el-form-item prop="imageCode" v-if="captchaEnabled">
          <el-input
              v-model="codeForm.imageCode"
              auto-complete="off"
              placeholder="验证码"
              style="width:50%"
              @keyup.enter.native="sendCode"
          >
          </el-input>
          <div class="register-code">
            <img :src="codePhoneUrl" @click="getCode()" class="register-code-img"/>
          </div>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="sendCode">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// import ImgVerify from '@/components/ImgVerify'
import { mixins } from '@/mixin'
import {getCodeImg, infoCollectFn, phoneCode} from "@/api/contactUs";
export default {
  name:'franchiseform',
  mixins:[ mixins ],
  // components:{ ImgVerify },
  data() {
    return ({
      franchise_form: {
        phone:'',
        phoneCode:'',
        remark:'',
        companyName: '',
        name:'',
        officialWebsiteChar:'yuetaixing',
      },
      codeName:'获取验证码',
      waitTime:60, // 获取验证码按钮失效时间
      check_message: '',
      dialogTableVisible:false,
      codeForm:{
        phone:'',
        imageCode:'',
        uuid:''
      },
      captchaEnabled: true,
      codePhoneUrl:'',
    })
  },
  watch: {
    franchise_form: {
      handler(oldVal, newVal) {
        if (newVal.phoneCode != '') this.check_message = ''
      },
      deep: true
    }
  },
  methods: {
    handleClose(done) {
      this.$confirm('确认关闭？')
          .then(_ => {
            done();
          })
          .catch(_ => {});
    },
    getPhoneCode(){
      let reg = /^1(3[0-9]|4[01456879]|5[0-35-9]|6[2567]|7[0-8]|8[0-9]|9[0-35-9])\d{8}$/
      if(!reg.test(this.franchise_form.phone)){
        this.$message.warning('请输入正确的手机号');
        return
      }
      this.dialogTableVisible = true;
      this.getCode();
    },
    getCode() {
      getCodeImg().then(res => {
        this.captchaEnabled = res.captchaEnabled === undefined ? true : res.captchaEnabled;
        if (this.captchaEnabled) {
          this.codePhoneUrl = "data:image/gif;base64," + res.img;
          this.codeForm.uuid = res.uuid;
        }
      });
    },
    // 获取验证码
    async sendCode(){
      if(this.codeForm.imageCode==''){
        this.$message.warning('请输入验证码');
        return
      }
      let data = {
        phone:this.franchise_form.phone,
        imageCode:this.codeForm.imageCode,
        uuid:this.codeForm.uuid,
      }
      const res = await phoneCode(data)
      this.dialogTableVisible = false;
      this.codeForm.imageCode = '';
      this.$message.success('验证码发送成功')
      let that = this;
      that.waitTime--;
      that.isCode = true;
      this.codeName = `${this.waitTime}s 后重新发送`;
      let timer = setInterval(function () {
        if (that.waitTime > 1) {
          that.waitTime--;
          that.codeName = `${that.waitTime}s 后重新发送`;
        } else {
          clearInterval(timer)
          that.codeName = '获取验证码';
          that.isCode = false;
          that.waitTime = 60;
        }
      }, 1000)
    },
    // 提交表单信息
    submit() {
      if (!this.franchise_form.name) {
        this.check_message = '请输入“您的姓名”'
        return
      }
      if (!this.franchise_form.phone) {
        this.check_message = '请输入“联系方式”'
        return
      }
      if (!this.franchise_form.companyName) {
        this.check_message = '请输入“公司名字”'
        return
      }
      if (!this.franchise_form.phoneCode) {
        this.check_message = '请输入“验证码”'
        return
      }
      console.log(this.franchise_form,'--form')
      infoCollectFn(this.franchise_form).then(res=>{
        this.$message.success('预约成功，请耐心等待工作人员的联系');
        this.dialogFormVisible = false;
        this.franchise_form = {};
      }).catch(err=>{
      })
    },
  }
}
</script>

<style scoped lang="scss">
.form {
  .item_code {
    width: 400px !important;
    .code{
      //flex: 0!important;
      width:200px;
    }
  }

  .check_message {
    margin: 15px 0;
    text-align: center;
    color: #ff0000;
  }

  .form_item {
    width: 480px;
    // height: 49px;
    margin-bottom: 9px;

    .form_item_label {
      color: #666666;
      margin-bottom: 8px;
      font-size: 14px;
      font-weight: 700;
    }

    .item_input {
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: rgb(102, 102, 102);

      .change {
        cursor: pointer;
        padding: 10px;
        border-radius: 5px;
        border: 1px solid #ccc;
        color: rgb(102, 102, 102);
      }

      textarea {
        height: 101px;
        line-height: 26px;
      }

      input {
        height: 35px;
      }

      input,
      textarea {
        flex: 1;
        border: 1px solid #dadada;
        border-radius: 2px;
        text-indent: 6px;
        font-size: 12px;
        color: #666666;
      }

      span {
        color: #ff0000;
        margin-left: 12px;
        font-size: 14px;
      }
    }
  }

  .submit {
    width: 168px;
    height: 44px;
    text-align: center;
    line-height: 44px;
    color: #fff;
    background: #5ab4ff;
    font-size: 14px;
    text-align: center;
    margin-top: 20px;
    cursor: pointer;
  }

}
input::placeholder {
  color: #9d9d9d;
}
textarea::placeholder {
  color: #9d9d9d;
}
.register-code {
  width: 40%;
  height: 48px;
  float: right;

  img {
    width: 100%;
    height: 100%;
    cursor: pointer;
    vertical-align: middle;
  }
}
::v-deep .el-dialog{
  width: 80%!important;
}
</style>