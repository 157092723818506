<template>
  <div class="franchise">
    <Header></Header>
    <BaseBanner :banner="'franchise'"></BaseBanner>
    <div class="franchise_main">
      <div class="franchise_content website_wrapper">
        <div class="category">
          <franch_category></franch_category>
        </div>
        <div class="franchise_from">
          <Franchiseform></Franchiseform>
          <div ref="animation" :class="['franchise_right_msg',franchise_right_msg?'animation_right_start_deial':'animation_opacity']">
            <div class="msg_main">
              <div class="msg_top">
                <div class="top_title">亲切的守护力量服务</div>
                <div class="top_title">让亲近更有价值</div>
              </div>
              <div class="msg_boottom">
                与岳泰兴医疗携手共进，引领医疗行业未来发展，为健康保驾护航
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import BaseBanner from '@/components/BaseBanner'
import Franchiseform from '@/components/franchise/Franchiseform'
import { mixins } from '@/mixin'
import Header from '@/components/common/Header';
import Footer from '@/components/common/Footer';
import Franch_category from "@/components/franchise/franch_category.vue";
export default {
  name: 'Franchise',
  components: {Franch_category, BaseBanner, Franchiseform,Header,Footer },
  mixins:[mixins],
  data() {
    return ({

    })
  },
}
</script>

<style scoped lang="scss">
.franchise {
  .franchise_main {
    .franchise_content {
      position: relative;
      top: 0;
      left: 0;
      height: 1200px;

      .category {
        position: absolute;
        top: 50px;
        left: 0;
      }

      .franchise_from {
        position: absolute;
        top: 650px;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 1200px;

        .franchise_right_msg {
          width: 610px;
          height: 100%;
          display: flex;
          align-items: center;

          .msg_top {
            margin-bottom: 30px;

            .top_title {
              font-size: 30px;
              color: $font_light_color;
            }
          }

          .msg_boottom {
            font-size: 14px;
            color: #666666;
            line-height: 30px;
          }

          margin-bottom: 100px;

        }
      }

    }
  }
}
</style>