<template>
  <div class="category">
    <div ref="animation" :class="['category_left',categoryRight?'animation_right_start':'animation_opacity']">
      <div class="category_title">岳泰兴医疗一直致力于推动医疗行业的数字化转型和创新发展，以打造更加普惠、高效、优质的医疗服务为使命。</div>
      <div>
        <b>—</b>
      </div>
      <div class="category_msg">
        我们期待与各界企业携手共进，共同探索和开拓医疗市场的无限可能性。通过强大的供应链、客户服务和技术支持等方面的赋能，我们将为合作伙伴提供更多的商业价值和社会价值，
        并共同实现双方的共赢发展。让我们携手并肩，共同推动医疗行业的变革和进步，为人类健康事业贡献更大的力量。
        </div>
      <div class="category_more" @click="moreBrand">
        查看更多
      </div>
    </div>
    <div :class="['category_right',categoryRight?'animation_right_start':'animation_opacity']">
      <div class="category_right_item">
        <div>
          <div class="item_title font_left">互联网医院</div>
          <div class="item_en_msg">INTERNET HOSPITALS</div>
        </div>
        <div class="item_num font_right">01</div>
      </div>
      <div class="category_right_item">
        <div>
          <div class="item_title font_right">智慧养老</div>
          <div class="item_en_msg_right">SMART ELDERLY CARE</div>
        </div>
        <div class="item_num">02</div>
      </div>
      <div class="category_right_item">
        <div class="item_num font_right">03</div>
        <div>
          <div class="item_title font_left">智慧医院</div>
          <div class="item_en_msg">SMART HOSPITAL</div>

        </div>
      </div>
      <div class="category_right_item">
        <div class="item_num">04</div>
        <div>
          <div class="item_title font_right">岳泰兴大药房</div>
          <div class="item_en_msg_right">YUETAIXING PHARAMCY</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mixins } from '@/mixin'
export default {
  name:'franch_category',
  mixins:[mixins],
  data(){
    return({
      flag:false
    })
  },
  methods: {
    moreBrand() {
      const routeUrl = this.$router.resolve({
        path: "/brand",
        query: {id:66},
      });
      window.open(routeUrl.href, "_blank");
    },
  },
}
</script>

<style scoped lang="scss">
.category {

  .category_left {
    margin-top: 60px;
    width: 540px;

    .category_title {
      color: $font_light_color;
      font-size: 30px;
    }

    b {
      color: $font_light_color;
      font-size: 40px;
      font-weight: 700;
      margin: 20px 0 0 0;
    }

    .category_msg {
      font-size: 14px;
      color: #666666;
      margin: 30px 0;
      line-height: 30px;
      width: 485px;
    }

    .category_more {
      box-sizing: border-box;
      margin-top: 50px;
      height: 40px;
      width: 105px;
      text-align: center;
      line-height: 40px;
      font-size: 14px;
      color: $font_light_color;
      border: 1px solid $font_light_color;
      cursor: pointer;
    }
  }

  .category_right {
    position: absolute;
    top: 0;
    left: 728px;
    width: 420px;
    height: 420px;
    background: url("~@/assets/img/home/category.jpg");
    background-size: cover;
    display: flex;
    flex-wrap: wrap;

    .font_left {
      text-align: left;
    }

    .font_right {
      text-align: right;
      margin-right: -20px;
    }

    .category_right_item {
      position: relative;
      box-sizing: border-box;
      width: 200px;
      height: 200px;
      color: #ffffff;
      padding: 30px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .item_title {
        font-weight: 700;
        font-size: 24px;
      }

      .item_en_msg {
        font-size: 14px;
        text-align: center;
        margin: 10px 0;
      }
      .item_en_msg_right{
        font-size: 14px;
        text-align: right;
        margin: 10px 0;
      }
      .item_num{
        color: #97e5ff;
        font-size: 36px;
        font-weight: 700;

      }
    }

    .category_right_item:nth-child(-n+2){
      .item_num{
        margin-bottom: -30px;
      }
    }
    .category_right_item:nth-child(n+2){
      .item_num{
        margin-top: -25px;
      }
    }

  }
}</style>